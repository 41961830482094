<template>
  <div>
   <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row
      ><div class="pl-5 pa-5 black-text lighten-3 display-1">
        Mark Student for Book Bank
      </div></v-row
    >
    <v-card class="grey lighten-4 pa-5 ma-5">
      <v-row align="center" justify="center">
        <v-col cols="12" md="3" sm="3">
          <label>Program Type :</label>
          <v-autocomplete
            solo
            :items="programtypelist"
            item-text="name"
            item-value="id"
            v-model="programtype"
            @change="getprogram"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="3">
          <label>Program :</label>
          <v-autocomplete solo
          :items="programlist"
            item-text="name"
            item-value="id"
            v-model="program"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row> <v-divider></v-divider> OR <v-divider></v-divider></v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="3" sm="3">
          <label>PRN NO :</label>
          <v-text-field v-model="prn" solo></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn class="primary" @click="getstudent">Search</v-btn>
      </v-row>
    </v-card>
    <v-card v-if="Array.isArray(studentlist) && studentlist.length" class="grey lighten-4 pa-5 ma-5">
     <v-row justify="end"
      ><v-col cols="12" sm="3" md='3'> <v-text-field placeholder="Search By PRN" append-icon="search" v-model="search" solo></v-text-field></v-col></v-row
    >
    <v-simple-table   class="grey lighten-4">
    <thead>
      <th> PRN </th>
       <th> NAME </th>
        <th> USERNAME </th>
         <th> PROGRAM </th>
          <th> Is Book Bank Policy </th>
    </thead>
    <tbody>
    <tr v-for="(i,index) in resultQuery" :key="index">
        <td>{{i.prn}}</td>
         <td>{{i.name}}</td>
          <td>{{i.username}}</td>
           <td>{{i.program}}</td>
            <td><v-checkbox v-model="i.isbookbankpolicy"></v-checkbox></td>
    </tr>
    </tbody>
    </v-simple-table>

     <v-row class="pa-5 ma-5" justify="center" align="center">
      <v-btn class="primary" @click="markstudent">update</v-btn>
    </v-row>
    </v-card>
   
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      program: "",
      programtype: "",

      programlist: [],
      programtypelist: [],

      prn:"",
      studentlist:[],
      color: "",
      search:"",
      searchlist:[],
    snackbar: false,
       snackbar_msg: "",
    };
  },
  mounted() {
    this.init();
  },
   computed: {
    resultQuery(){
      if(this.search!=''){
      return this.studentlist.filter((item)=>{
        return this.search.toLowerCase().split(' ').every(v => item.prn.toLowerCase().includes(v))
      })
      }else{
        return this.studentlist;
      }
    }
  },
  methods: {
   markstudent()
   {
          var params={
            "studentlist":this.studentlist
        }
      axios.post("/Librarian/markstusentforbookbank",params).then((res) => {
        if (res.data.msg == "200") {
        this.showSnackbar("green","Sucessfully updated..")
        }
      });
   },
    init() {
      axios.post("/Librarian/getprogramtype").then((res) => {
        if (res.data.msg == "200") {
          this.programtypelist = res.data.programtypelist;
        }
      });
    },
    getprogram() {
        var params={
            "programtypeid":this.programtype
        }
      axios.post("/Librarian/getprogram",params).then((res) => {
        if (res.data.msg == "200") {
          this.programlist = res.data.programlist;
        }
      });
    },
   showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    getstudent() {
  
      if(this.program=='' || this.programtype==''  )
      {
          this.showSnackbar("red","Please select program and program type...")
      }
      else{
            this.studentlist=[];
        var params={
            "program":this.program,
            "prn":this.prn
        }
      axios.post("/Librarian/getstudent",params).then((res) => {
        if (res.data.status == "200") {
          this.studentlist = res.data.studentlist;
        
        }
        else{
             this.showSnackbar("red","No Data found.")
        }
      });
      }
    
    },

  },
};
</script>